export default (state, action) => {
    if (typeof state === "undefined") {
        return [];
    }
    switch (action.type) {
        case "GET_DASHBOARD_DETAILS_SUCCESS":
        case "GET_DASHBOARD_DETAILS_ERROR":
            return {
                ...state,
                GetDashboardDetailsResponse: action.updatePayload,
            };
        case "FAKE_ACTION_DASHBOARD":
            return [];
        default:
            return state;
    }
};
