import { createGlobalStyle } from "styled-components";

const AppStyleWrapper = createGlobalStyle`
.loader {
  width: 40px;
  height: 40px;
  display: inline-block;
  border: 5px solid var(--bs-primary);
  border-radius: 50%;
  border-top-color: transparent;
  border-bottom-color: transparent;
  animation: rot5 1s infinite;
}
.line-clamp {
    display: -webkit-box;
    -webkit-box-orient: vertical;
    overflow: hidden;
}
.shimmer {
    -webkit-mask: linear-gradient(-60deg, #000 30%, #0005, #000 70%) right/300%
        100%;
    background-repeat: no-repeat;
    animation: shimmer 2.5s infinite;
}
@keyframes shimmer {
    100% {
        -webkit-mask-position: left;
    }
}
@keyframes rot5 {
  0% {
    transform: rotate(0);
  }

  50% {
    transform: rotate(180deg);
    border-top-color: var(--bs-primary);
    border-bottom-color: var(--bs-primary);
    border-right-color: transparent;
    border-left-color: transparent;
  }

  100% {
    transform: rotate(360deg);
  }
}
.ant-message-top {
    z-index: 99999999999999999999 !important;
}

::-webkit-scrollbar {
  width: 5px;
  height: 5px;
  scroll-behavior: smooth;
}

::-webkit-scrollbar-track {
  background: #f1f1f100; 
}
 
::-webkit-scrollbar-thumb {
  background: #888; 
}

::-webkit-scrollbar-thumb:hover {
  background: #555; 
}

.order-img img:hover {
    transition: 0.3s;
    width: 75px !important;
}

.ant-pagination {
    .ant-pagination-item-active {
        background-color: var(--bs-primary-active) !important;
        border-color: var(--bs-primary-active) !important;
        color: var(--bs-primary-inverse) !important;
    }
}
.ant-dropdown-menu {
    background-color: var(--bs-menu-dropdown-bg-color) !important;
    box-shadow: var(--bs-menu-dropdown-box-shadow) !important;
}
.ant-dropdown {
    font-family:unset;
    .ant-dropdown-arrow:before {
        background: var(--bs-menu-dropdown-bg-color) !important;
    }
}
.ant-dropdown-menu-item {
    color: var(--bs-text-gray-700) !important;
    &:hover {
        color: var(--bs-menu-link-color-active) !important;
        background-color: var(--bs-menu-link-bg-color-active) !important;
        .svg-icon {
            color: var(--bs-menu-link-color-active) !important;
        }
    }
}

.ant-dropdown-menu-item-selected {
    transition: color .2s ease;
    color: var(--bs-menu-link-color-active) !important;
    background-color: var(--bs-menu-link-bg-color-active) !important;
    .svg-icon {
        color: var(--bs-menu-link-color-active) !important;
    }
}

#root {
    height: 100%;
}
#root > .globalHeight {
    display: flex;
    flex-direction: column;
    height: 100%;
}
.ant-form-item .ant-form-item-label >label {
    color: var(--bs-body-color) !important;
}
.ant-empty-description {
    color: var(--bs-body-color) !important;
}
.ant-input-number {
    border: 1px solid var(--bs-gray-300) !important;
    background-color: var(--bs-body-bg)  !important;
}
.ant-input-number.ant-input-number-status-error {
    border-color: #ff4d4f !important;
}
.ant-input-number.ant-input-number-focused{
    border: 1px solid var(--bs-gray-800) !important;
    box-shadow: unset !important;
}
.ant-picker-input {
    input {
    color: var(--bs-input-solid-color) !important;
    }
    svg {
            color: var(--bs-primary) !important;
            background: var(--bs-menu-dropdown-bg-color) !important;
        }
}
.ant-picker-panel-container  {
    background-color: var(--bs-body-bg) !important;
    .ant-picker-time-panel-cell-inner {
    color: var(--bs-input-solid-color) !important;
    }
    .ant-picker-time-panel-cell-selected {
        .ant-picker-time-panel-cell-inner {
            background-color: var(--bs-component-active-bg) !important;
            color: var(--bs-component-active-color) !important;
        }
    }
}
.ant-input-password {
    background-color: var(--bs-input-solid-bg)  !important;
    border: 1px solid var(--bs-light-inverse) !important;
    .ant-input {
        background-color: var(--bs-input-solid-bg)  !important;
        border: 1px solid transparent !important;
    }
}
.overlayLoading {
    margin: auto;
    width: fit-content;
    height: fit-content;
    inset: 0;
    position: absolute;
    z-index: 99999999;
}
.ant-radio-group {
    label {
        span {
            color: var(--bs-input-solid-color) !important;
        }
    }
}
#kt_modal_add, #kt_modal_add_customer {
    background: #0000004f;
}
.ant-upload-list-item-error {
    border-color: var(--bs-light-inverse) !important;
}
.ant-popover-arrow-content {
    --antd-arrow-background-color: var(--bs-page-bg) !important;
}
.ant-popover {
    background:#fff !important;
    .ant-popover-arrow:before {
        background: var(--bs-page-bg) !important;
    }
    .ant-popover-inner {
        background-color: var(--bs-page-bg) !important;
        .ant-popconfirm-message {
            color: var(--bs-table-color) !important;
        }
        .ant-popconfirm-description {
            color: var(--bs-text-muted) !important;
            b {
                color: var(--bs-table-color) !important;
            }
        }
    }
    .ant-popconfirm-buttons {
        .ant-btn-default {
            background-color: var(--bs-light) !important;
            color: var(--bs-text-muted)  !important;
            border: none !important;
        }
        .ant-btn-primary {
            color: var(--bs-danger-inverse) !important;
            background-color: var(--bs-danger) !important;
            border: none !important;
        }
    }
}

a {
    cursor: pointer;
}
.ant-upload-list-item {
        .ant-upload-list-item-actions > a:first-child {
            display: none !important;
        }
}
.ant-menu-root.ant-menu-inline {
    border-inline-end: none !important;
}
.ant-select.header-client .ant-select-selector{
    border-radius: 40px !important;
}
.ant-select .ant-select-selector .ant-select-selection-item{
    color: var(--bs-input-color) !important;
	font-size: .95rem !important;
    font-weight: 400 !important;
}
.ant-select .ant-select-selector .ant-select-selection-placeholder {
    font-size:15px;
}
.ant-modal .ant-modal-content{
    background-color: var(--bs-body-bg) !important;
    padding:0
    color: unset !important;
}

.ant-modal {
    font-family : Inter, Helvetica, sans-serif;
    .modal-header {
        display: flex;
        flex-shrink: 0;
        align-items: center;
        justify-content: space-between;
        padding: 0.75rem 0.75rem;
        border-bottom: 1px solid var(--bs-border-color);
        border-top-left-radius: 0.475rem;
        border-top-right-radius: 0.475rem;
    }
    .ant-modal-footer {
        display: flex;
        flex-shrink: 0;
        flex-wrap: wrap;
        align-items: center;
        justify-content: flex-end;
        padding: calc(1rem - 0.5rem * .5);
        border-top: 1px solid var(--bs-border-color);
        border-bottom-right-radius: 0.475rem;
        border-bottom-left-radius: 0.475rem;
        margin-top:0px;
    }
}
:where(.css-dev-only-do-not-override-a7mxd6).ant-select:not(.ant-select-disabled):not(.ant-select-customize-input):not(.ant-pagination-size-changer):hover .ant-select-selector {
    border-color: #0d0d0d !important;
}

.ant-select:not(.dashboard-select) .ant-select-selector{
    background-color: var(--bs-light-active) !important;
    border: none !important;
}

:where(.css-dev-only-do-not-override-1selewd).ant-select-dropdown{
    background-color: var(--bs-dropdown-bg) !important;
}

:where(.css-dev-only-do-not-override-1selewd).ant-select-dropdown .ant-select-item{
        color: var(--bs-gray-700); !important;
}

:where(.css-dev-only-do-not-override-1selewd).ant-select-dropdown .ant-select-item-option-active:not(.ant-select-item-option-disabled){
    background-color: var(--bs-component-hover-bg) !important;
    color: var(--bs-component-hover-color) !important;
}
:where(.css-dev-only-do-not-override-1selewd).ant-select-dropdown .ant-select-item-option-selected:not(.ant-select-item-option-disabled){
    color: var(--bs-component-hover-color) !important;
    background-color: var(--bs-component-hover-bg) !important;
}

:where(.css-dev-only-do-not-override-1selewd).ant-picker-outlined{
    background-color: var(--bs-input-solid-bg) !important;
}

.ant-input{
    background-color: var(--bs-body-bg) !important;
    border: 1px solid var(--bs-gray-300) !important;
}

.ant-picker-dropdown .ant-picker-range-wrapper .ant-picker-panel-container .ant-picker-panel-layout .ant-picker-presets ul {
    color: var(--bs-gray-600) !important;
}
.ant-picker-dropdown .ant-picker-range-wrapper .ant-picker-panel-container .ant-picker-panel-layout .ant-picker-panels{
    .ant-picker-header button{
        color: var(--bs-gray-800) !important;
    }
    .ant-picker-body table thead tr th{
        color: var(--bs-gray-800) !important;
    }
    .ant-picker-body table tbody tr .ant-picker-cell{
        color: var(--bs-gray-400) !important;
    }
    .ant-picker-body table tbody tr .ant-picker-cell.ant-picker-cell-in-view{
        color: var(--bs-gray-700) !important;
    }
    .ant-picker-body table tbody tr {
        .ant-picker-cell-in-range:before{
            background-color: var(--bs-component-hover-bg) !important;
        }
        .ant-picker-cell-range-start:before{
            background:  var(--bs-component-hover-color);
        }
        .ant-picker-cell-range-end:before{
            background:  var(--bs-component-hover-color);
        }
        .ant-picker-cell-range-hover-start:before{
            background: unset;
        }
        .ant-picker-cell-range-start .ant-picker-cell-inner{
            background-color: var(--bs-component-hover-color) !important;
        }
        .ant-picker-cell-range-end .ant-picker-cell-inner{
            background-color: var(--bs-component-hover-color) !important;
        }
    }
    .ant-picker-body table tbody tr .ant-picker-cell-in-range .ant-picker-cell-inner{
        color:  var(--bs-component-hover-color) !important;
    }
}
.custom-model .modal-body {
    height: 500px;
    overflow: auto;
}
.ant-drawer .ant-drawer-content-wrapper {
    height: 70% !important;
    width: unset !important;
}
.ant-picker-dropdown .ant-picker-content{
    .ant-picker-cell-selected .ant-picker-cell-inner{
        background: var(--bs-component-active-bg) !important;
    }
}
.ant-picker-dropdown .ant-picker-footer{
    .ant-picker-ok button{
        background-color: var(--bs-primary) !important;
    }
    .ant-picker-now a:hover{
        color: var(--bs-component-active-bg) !important;
    }
}
.error-msg{
    color: rgba(255, 0, 0, 0.815);
}
.image-stack {
    position: relative;
    width: 100%;
}
.image-stack__item--bottom {
    position: absolute;
    right: 20px;
    top: 0;
    width: 80%;
}
  
.image-stack__item--top {
    padding-top: 10px; 
    padding-right: 20%;
    width: 80%;
}
.client-model .modal-body {
    margin-top: 8px;
    max-height: 500px;
    overflow: auto;
}
.reimbursement-custom-text-box{
    height:30px;
    font-size:12px;
    padding-left:10px;
    position: unset;
} 
.ant-dropdown .menu {
    border-radius: 0.475rem;
    background-color: var(--bs-menu-dropdown-bg-color) !important;
    box-shadow: var(--bs-menu-dropdown-box-shadow) !important;
}
.import-sub-menu {
    background-color: #ffffff !important;
    box-shadow: 0px 0px 50px 0px rgba(82, 63, 105, 0.15);
    border-radius: 0.475rem;
    flex-direction: column;
}
.custom-table-style{
    max-height: 70vh;
    thead{
        position : sticky;
        top: 0px;
        z-index: 1;
    }
    tbody{
        overflow-y: auto;
    }
}
.tbl-first-three tr td:nth-child(3),
{
    position: sticky;
    left: 0;
    background: #fff;
}
.tbl-first-three tr th:nth-child(3),
{
    position: sticky;
    left: 0;
    background: var(--bs-gray-100) !important;
}

.tbl-first-three tr td:nth-child(4),
{
    position: sticky;
    left: 105px;
    background: #fff;
}
.tbl-first-three tr th:nth-child(4),
{
    position: sticky;
    left: 105px;
    background: var(--bs-gray-100) !important;
}

.tbl-first-three tr td:nth-child(5),
{
    position: sticky;
    left: 205px;
    background: #fff;
}
.tbl-first-three tr th:nth-child(5),
{
    position: sticky;
    left: 205px;
    background: var(--bs-gray-100) !important;
}
.tbl-first-three.lost tr td:nth-child(16),
{
    position: sticky;
    left: 335px;
    background: #fff;
}
.tbl-first-three.lost tr th:nth-child(16),
{
    position: sticky;
    left: 335px;
    background: var(--bs-gray-100) !important;
}
.tbl-first-three.damaged tr td:nth-child(14),
{
    position: sticky;
    left: 335px;
    background: #fff;
}
.tbl-first-three.damaged tr th:nth-child(14),
{
    position: sticky;
    left: 335px;
    background: var(--bs-gray-100) !important;
}
.tbl-first-three.disposed tr td:nth-child(12),
{
    position: sticky;
    left: 335px;
    background: #fff;
}
.tbl-first-three.disposed tr th:nth-child(12),
{
    position: sticky;
    left: 335px;
    background: var(--bs-gray-100) !important;
}
.reimbursement-custom-text-box {
    .ant-input-number-handler-wrap{
        display: none !important;
    }
    .ant-input-number-input-wrap input{
        padding-left : 0px !important;
    }
}
.inbound-column-freeze tr td:nth-child(2),
{
    position: sticky;
    left: 0;
    background: #fff;
}
.inbound-column-freeze tr th:nth-child(2),
{
    position: sticky;
    left: 0;
    background: var(--bs-gray-100) !important;
}
.inbound-column-freeze tr td:nth-child(3),
{
    position: sticky;
    left: 120px;
    background: #fff;
}
.inbound-column-freeze tr th:nth-child(3),
{
    position: sticky;
    left: 120px;
    background: var(--bs-gray-100) !important;
}
.inbound-column-freeze tr td:nth-child(13),
{
    position: sticky;
    left: 230px;
    background: #fff;
}
.inbound-column-freeze tr th:nth-child(13),
{
    position: sticky;
    left: 230px;
    background: var(--bs-gray-100) !important;
}
`;

export default AppStyleWrapper;
