import React from 'react'
import { Link } from "react-router-dom";
import moment from 'moment'

export default function () {
    return (
        <div id="kt_app_footer" className="app-footer">
            <div className="app-container container-fluid d-flex flex-column flex-md-row flex-center flex-md-stack py-3">
                <div className="text-dark order-2 order-md-1">
                    <span className="fw-semibold me-.0">Copyright © {moment().format("YYYY")} </span>
                    <a href target="_blank" className="text-primary fw-bolder text-hover-success">Execuseller </a>
                    <span className="fw-semibold me-1">All rights reserved.</span>
                </div>
                <ul className="menu menu-gray-600 menu-hover-primary fw-semibold order-1">
                    <li className="menu-item">
                        <Link target="_blank" className="menu-link px-2">About</Link>
                    </li>
                    <li className="menu-item">
                        <Link target="_blank" className="menu-link px-2">Support</Link>
                    </li>
                </ul>
            </div>
        </div>
    )
}