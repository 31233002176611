export default (state, action) => {
    if (typeof state === "undefined") {
        return [];
    }
    switch (action.type) {
        case "GET_INVENTORY_LEDGER_REPORT_LIST_SUCCESS":
        case "GET_INVENTORY_LEDGER_REPORT_LIST_ERROR":
            return {
                ...state,
                GetInventoryLedgerReportResponse: action.updatePayload,
            };
        case "FAKE_ACTION_INVENTORY_LEDGER_REPORT":
            return [];
        default:
            return state;
    }
};
