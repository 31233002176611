export const nameObject = {
  "/": {
    name: "Dashboard",
  },
  "/dashboard": {
    name: "Dashboard",
  },
  "/manage-user": {
    name: "Manage User",
  },
  "/geo-currency-manager": {
    name: "Geo Currency Manager",
  },
  "/billing": {
    name: "Billing",
  },
  "/monthly-report": {
    name: "Monthly Report",
  },
  "/reimbursement-details": {
    name: "Reimbursement Details",
  },
  "/reports/inventory-ledger-report": {
    name: "Inventory Ledger Report",
    parent: "Reports",
  },
  "/reports/reimbursement-report": {
    name: "Reimbursement Report",
    parent: "Reports",
  },
  "/settings/profile": {
    name: "My Profile",
    parent: "Settings",
  },
  "/settings/add-manager": {
    name: "Add Manager",
    parent: "Settings",
  },
  "/settings/marketplace-credentials": {
    name: "Marketplace",
    parent: "Settings",
  },
  "/application-logs/central-log": {
    name: "Central Log",
    parent: "Application Logs",
  },
  "/application-logs/system-error-log": {
    name: "System Error Log",
    parent: "Application Logs",
  },
};
