export default (state, action) => {
  if (typeof state === "undefined") {
    return [];
  }
  switch (action.type) {
    case "GET_COUNTRY_MANAGER_LIST_SUCCESS":
    case "GET_COUNTRY_MANAGER_LIST_ERROR":
      return {
        ...state,
        GetCountryManagerListResponse: action.updatePayload,
      };

    case "ADD_COUNTRY_MANAGER_SUCCESS":
    case "ADD_COUNTRY_MANAGER_ERROR":
      return {
        ...state,
        AddCountryManagerResponse: action.updatePayload,
      };
    case "UPDATE_COUNTRY_MANAGER_SUCCESS":
    case "UPDATE_COUNTRY_MANAGER_ERROR":
      return {
        ...state,
        UpdateCountryManagerResponse: action.updatePayload,
      };
    case "DELETE_COUNTRY_MANAGER_SUCCESS":
    case "DELETE_COUNTRY_MANAGER_ERROR":
      return {
        ...state,
        DeleteCountryManagerResponse: action.updatePayload,
      };
    case "FAKE_ACTION_CURRENCY_MANAGER":
      return [];
    default:
      return state;
  }
};
