export default (state, action) => {
    if (typeof state === "undefined") {
        return [];
    }
    switch (action.type) {
        case "GET_CENTRAL_LOG_SYSTEM_LIST_SUCCESS":
        case "GET_CENTRAL_LOG_SYSTEM_LIST_ERROR":
            return {
                ...state,
                GetCentralLogSystemErrorListResponse: action.updatePayload,
            };
        // case "GET_USER_SYSTEM_EVENT_LOGS_SUCCESS":
        // case "GET_USER_SYSTEM_EVENT_LOGS_ERROR":
        //     return {
        //         ...state,
        //         GetUserSystemEventLogsResponse: action.updatePayload,
        //     };
        case "GET_EVENT_LIST_SUCCESS":
        case "GET_EVENT_LIST_ERROR":
            return {
                ...state,
                GetEventListResponse: action.updatePayload,
            };
        case "GET_EVENT_TYPE_LIST_SUCCESS":
        case "GET_EVENT_TYPE_LIST_ERROR":
            return {
                ...state,
                GetEventTypeListResponse: action.updatePayload,
            };
        case "FAKE_ACTION_USER_APPLICATION_LOG_CENTRAL":
            return [];
        default:
            return state;
    }
};
