import axios from "axios";

export const AppId = "";
export const EndPoint = "https://app.execuseller.com/execu_seller/";
export const returnURL = "";
export const clientID = "";
export const timeZone = "America/Los_Angeles";

export const timeSince = (date) => {
    var seconds = Math.floor((new Date() - date * 1000) / 1000);

    var interval = seconds / 31536000;

    if (interval > 1) {
        return Math.floor(interval) + " years ago";
    }
    interval = seconds / 2592000;
    if (interval > 1) {
        return Math.floor(interval) + " months ago";
    }
    interval = seconds / 86400;
    if (interval > 1) {
        return Math.floor(interval) + " days ago";
    }
    interval = seconds / 3600;
    if (interval > 1) {
        return Math.floor(interval) + " hours ago";
    }
    interval = seconds / 60;
    if (interval > 1) {
        return Math.floor(interval) + " minutes ago";
    }
    return Math.floor(seconds) + " seconds ago";
};

export const urlDecode = (url) => {
    let newObj = {};
    url?.search
        ?.split("?")[1]
        ?.split("&")
        ?.forEach((e) => {
            const obj = e?.split("=");
            newObj = { ...newObj, [obj[0]]: obj[1] };
        });
    return newObj || {};
};

export const clearLocalData = () => {
    // localStorage.clear();
    // window.location.assign("/login");
    // window.location.reload();
};

export const handleStorageChange = (e) => {
    e.stopPropagation();
    if (e.url === window.location.href) {
        if (!e.key) {
            clearLocalData();
        } else {
            localStorage.setItem(e.key, e.oldValue);
        }
    }
    if (
        !localStorage.getItem("token") ||
        !localStorage.getItem("user") ||
        !localStorage.getItem("userType") ||
        !localStorage.getItem("client-list") ||
        !localStorage.getItem("adminData")
    ) {
        clearLocalData();
    }
};

export const initialCall = async () => {
    axios({
        url: `${EndPoint}api/v1/all-users?no_pagination=true`,
        method: "GET",
        headers: { 'Authorization': `Bearer ${localStorage.getItem("token")}` }
    })
        .then((res) => {
            localStorage.setItem('client-list', JSON.stringify(res.data.data.records) || [])
        })
        .catch((error) => {
            if (error.response.data.status === 401) {
                if (error.response.data.name === "Unauthorized") {
                    localStorage.clear()
                    window.location.href = '/login'
                } else if (error.response.data.refresh_expire) {
                    return error.response
                }
            } else {
                return error.response
            }
        });
}