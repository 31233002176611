export default (state, action) => {
    if (typeof state === "undefined") {
        return [];
    }
    switch (action.type) {
        case "GET_AMAZON_SP_API_LIST_AMAZON_SUCCESS":
        case "GET_AMAZON_SP_API_LIST_AMAZON_ERROR":
            return {
                ...state,
                GetAmazonSPApiListResponse: action.updatePayload,
            };
        case "GET_MARKETPLACE_LIST_AMAZON_SUCCESS":
        case "GET_MARKETPLACE_LIST_AMAZON_ERROR":
            return {
                ...state,
                GetMarketPlaceListResponse: action.updatePayload,
            };
        case "GENERATE_REFRESH_TOKEN_SUCCESS":
        case "GENERATE_REFRESH_TOKEN_ERROR":
            return {
                ...state,
                GenerateTokenRefreshResponse: action.updatePayload,
            };
        case "FAKE_ACTION_MARKETPLACE":
            return [];
        default:
            return state;
    }
};
