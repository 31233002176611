import { createStore, combineReducers, applyMiddleware, compose } from "redux";
import { createHashHistory } from "history";
import { thunk } from 'redux-thunk';
import { routerMiddleware } from "connected-react-router";
import reducers from "../reducer";

const history = createHashHistory();

const rootReducer = combineReducers({
    ...reducers,
});

const routeMiddleware = routerMiddleware(history);

const middleware = [thunk, routeMiddleware];

const composeEnhancers =
    process.env.NODE_ENV === 'development' &&
        typeof window === "object" &&
        window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
        ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({})
        : compose;

const store = createStore(
    rootReducer,
    composeEnhancers(applyMiddleware(...middleware))
);

export { store, history };