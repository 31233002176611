export default (state, action) => {
    if (typeof state === "undefined") {
        return [];
    }
    switch (action.type) {
        case "GET_REIMBURSEMENT_DETAILS_LIST_SUCCESS":
        case "GET_REIMBURSEMENT_DETAILS_LIST_ERROR":
            return {
                ...state,
                GetReimbursementDetailListResponse: action.updatePayload,
            };
        case "GET_LOST_INBOUND_LIST_SUCCESS":
        case "GET_LOST_INBOUND_LIST_ERROR":
            return {
                ...state,
                GetLostInboundListResponse: action.updatePayload,
            };
        case "GET_LOST_OUTBOUND_LIST_SUCCESS":
        case "GET_LOST_OUTBOUND_LIST_ERROR":
            return {
                ...state,
                GetLostOutboundListResponse: action.updatePayload,
            };
        case "GET_FBA_FEES_LIST_SUCCESS":
        case "GET_FBA_FEES_LIST_ERROR":
            return {
                ...state,
                GetFbaFeesListResponse: action.updatePayload,
            };
        case "GET_DAMAGED_RETURN_LIST_SUCCESS":
        case "GET_DAMAGED_RETURN_LIST_ERROR":
            return {
                ...state,
                GetDamagedReturnListResponse: action.updatePayload,
            };
        case "GET_WRONG_FNSKU_LIST_SUCCESS":
        case "GET_WRONG_FNSKU_LIST_ERROR":
            return {
                ...state,
                GetWrongFnskuListResponse: action.updatePayload,
            };
        case "GET_ORDER_DISCREPANCY_LIST_SUCCESS":
        case "GET_ORDER_DISCREPANCY_LIST_ERROR":
            return {
                ...state,
                GetOrderDiscrepancyListResponse: action.updatePayload,
            };
        case "GET_DISPOSITION_LIST_SUCCESS":
        case "GET_DISPOSITION_LIST_ERROR":
            return {
                ...state,
                GetDispositionListResponse: action.updatePayload,
            };
        case "GET_REPORT_DATA_SUCCESS":
        case "GET_REPORT_DATA_ERROR":
            return {
                ...state,
                GetReportDataResponse: action.updatePayload,
            };
        case "GET_CASE_ID_LIST_SUCCESS":
        case "GET_CASE_ID_LIST_ERROR":
            return {
                ...state,
                GetCaseIdListResponse: action.updatePayload,
            };
        case "UPDATE_REIMBURSEMENT_DETAIL_SUCCESS":
        case "UPDATE_REIMBURSEMENT_DETAIL_ERROR":
            return {
                ...state,
                UpdateReimbursementDetailResponse: action.updatePayload,
            };
        case "UPDATE_LOST_INBOUND_DETAIL_SUCCESS":
        case "UPDATE_LOST_INBOUND_DETAIL_ERROR":
            return {
                ...state,
                UpdateReimbursementDetailResponse: action.updatePayload,
            };
        case "UPDATE_LOST_OUTBOUND_DETAIL_SUCCESS":
        case "UPDATE_LOST_OUTBOUND_DETAIL_ERROR":
            return {
                ...state,
                UpdateReimbursementDetailResponse: action.updatePayload,
            };
        case "UPDATE_DAMAGED_RETURN_DETAIL_SUCCESS":
        case "UPDATE_DAMAGED_RETURN_DETAIL_ERROR":
            return {
                ...state,
                UpdateReimbursementDetailResponse: action.updatePayload,
            };
        case "UPDATE_FBA_FEES_DETAIL_SUCCESS":
        case "UPDATE_FBA_FEES_DETAIL_ERROR":
            return {
                ...state,
                UpdateReimbursementDetailResponse: action.updatePayload,
            };
        case "IMPORT_FBA_FEES_SUCCESS":
        case "IMPORT_FBA_FEES_ERROR":
            return {
                ...state,
                ImportFileResponse: action.updatePayload,
            };
        case "IMPORT_DAMAGED_WAREHOUSE_SUCCESS":
        case "IMPORT_DAMAGED_WAREHOUSE_ERROR":
            return {
                ...state,
                ImportFileResponse: action.updatePayload,
            };
        case "IMPORT_LOST_INBOUND_SUCCESS":
        case "IMPORT_LOST_INBOUND_ERROR":
            return {
                ...state,
                ImportFileResponse: action.updatePayload,
            };
        case "FAKE_ACTION_REIMBURSEMENT_DETAILS":
            return [];
        default:
            return state;
    }
};
