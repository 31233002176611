export const adminMenus = [
  {
    title: "Dashboard",
    icon: <i className="ki-outline ki-element-11 fs-1" />,
    id: "dashboard",
    link: "/",
    subMenu: [],
  },
  {
    title: "Manage Users",
    icon: <i className="ki-outline ki-profile-user fs-1" />,
    id: "manage-user",
    link: "/manage-user",
    subMenu: [],
  },
  {
    title: "Geo Currency Manager",
    icon: <i className="ki-outline ki-dollar fs-1" />,
    id: "geo-currency-manager",
    link: "/geo-currency-manager",
    subMenu: [],
  },
];

export const userMenus = [
  {
    title: "Dashboard",
    icon: <i className="ki-outline ki-element-11 fs-1" />,
    id: "dashboard",
    link: "/",
    subMenu: [],
  },
  {
    title: "Billing",
    icon: <i className="ki-outline ki-directbox-default fs-1" />,
    id: "billing",
    link: "/billing",
    subMenu: [],
  },
  // {
  //   title: "Monthly Report",
  //   icon: <i className="ki-outline ki-abstract-26 fs-1" />,
  //   id: "monthly-report",
  //   link: "/monthly-report",
  //   subMenu: [],
  // },
  {
    title: "Reimbursement Details",
    icon: <i className="ki-outline ki-abstract-26 fs-1" />,
    id: "reimbursement-details",
    link: "/reimbursement-details",
    subMenu: [],
  },
  {
    title: "Reports",
    icon: <i className="ki-outline ki-questionnaire-tablet fs-1" />,
    id: "reports",
    subMenu: [
      {
        title: "Inventory Ledger Report",
        id: "inventory-ledger-report",
        link: "/reports/inventory-ledger-report",
      },
      {
        title: "Reimbursement Report",
        id: "reimbursement-report",
        link: "/reports/reimbursement-report",
      },
    ],
  },
  {
    title: "Settings",
    icon: <i className="ki-outline ki-gear fs-1" />,
    id: "settings",
    subMenu: [
      {
        title: "My Profile",
        id: "profile",
        link: "/settings/profile",
      },
      {
        title: "Add Manager",
        id: "add-manager",
        link: "/settings/add-manager",
      },
      {
        title: "Marketplace Credentials",
        id: "marketplace-credentials",
        link: "/settings/marketplace-credentials",
      },
    ],
  },
  {
    title: "Application Logs",
    icon: <i className="ki-outline ki-chart-simple-2 fs-1" />,
    id: "application-logs",
    subMenu: [
      {
        title: "Central Log",
        id: "central-log",
        link: "/application-logs/central-log",
      },
      {
        title: "System Error Log",
        id: "system-error-log",
        link: "/application-logs/system-error-log",
      },
    ],
  },
];
