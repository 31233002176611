import React, { Suspense } from "react";
import { Routes, Route, Navigate } from "react-router-dom";
import asyncComponent from "../core/asyncComponent";

const Dashboard_ = React.lazy(() => import("../containers/dashboard"));
const ManageUser_ = React.lazy(() => import("../containers/admin/manage-user"));
const GeoCurrencyManager_ = React.lazy(() =>
  import("../containers/admin/geo-currency-manager")
);
const Billing_ = React.lazy(() => import("../modules/page/user-panel/billing"));
const MonthlyReport_ = React.lazy(() =>
  import("../containers/user-panel/monthly-report")
);
const ReimbursementDetails_ = React.lazy(() =>
  import("../containers/user-panel/reimbursement-details")
);
const ReportsInventoryLedger_ = React.lazy(() =>
  import("../containers/user-panel/reports/inventory-ledger-report")
);
const ReportsReimbursement_ = React.lazy(() =>
  import("../containers/user-panel/reports/reimbursement-report")
);
const SettingsMyProfile_ = React.lazy(() =>
  import("../containers/user-panel/setting/profile")
);
const SettingsAddManager_ = React.lazy(() =>
  import("../containers/user-panel/setting/add-manager")
);
const SettingsMarketPlaceCredentials_ = React.lazy(() =>
  import("../containers/user-panel/setting/marketPlace")
);
const ApplicationCentralLog_ = React.lazy(() =>
  import("../containers/user-panel/application-logs/central-log-system")
);
const ApplicationSystemErrorLog_ = React.lazy(() =>
  import("../containers/user-panel/application-logs/system-error-logs")
);

const AsyncDashboard = asyncComponent(Dashboard_);
const AsyncManageUser = asyncComponent(ManageUser_);
const AsyncBilling = asyncComponent(Billing_);
const AsyncReimbursementDetails = asyncComponent(ReimbursementDetails_);
const AsyncReportsInventoryLedger = asyncComponent(ReportsInventoryLedger_);
const AsyncReportsReimbursement = asyncComponent(ReportsReimbursement_);
const AsyncSettingsMyProfile = asyncComponent(SettingsMyProfile_);
const AsyncSettingsAddManager = asyncComponent(SettingsAddManager_);
const AsyncSettingsMarketPlaceCredentials = asyncComponent(
  SettingsMarketPlaceCredentials_
);
const AsyncApplicationCentralLog = asyncComponent(ApplicationCentralLog_);
const AsyncApplicationSystemErrorLog = asyncComponent(
  ApplicationSystemErrorLog_
);

function PageRouter() {
  return (
    <Suspense
      fallback={
        <div className="overlayLoading">
          <div className="loader"></div>
        </div>
      }
    >
      <Routes>
        <Route path="/" element={<AsyncDashboard />} exact />
        <Route path="dashboard" element={<AsyncDashboard />} exact />
        <Route path="/manage-user" element={<AsyncManageUser />} exact />
        <Route
          path="/geo-currency-manager"
          element={<GeoCurrencyManager_ />}
          exact
        />
        <Route path="/billing" element={<AsyncBilling />} exact />
        <Route path="/monthly-report" element={<MonthlyReport_ />} exact />
        <Route
          path="/reimbursement-details"
          element={<AsyncReimbursementDetails />}
          exact
        />
        <Route
          path="/reports/inventory-ledger-report"
          element={<AsyncReportsInventoryLedger />}
          exact
        />
        <Route
          path="/reports/reimbursement-report"
          element={<AsyncReportsReimbursement />}
          exact
        />
        <Route
          path="/settings/profile"
          element={<AsyncSettingsMyProfile />}
          exact
        />
        <Route
          path="/callbackspapi"
          element={<AsyncSettingsMarketPlaceCredentials />}
          exact
        />
        <Route
          path="/settings/marketplace-credentials"
          element={<AsyncSettingsMarketPlaceCredentials />}
          exact
        />
        <Route
          path="/settings/add-manager"
          element={<AsyncSettingsAddManager />}
          exact
        />
        <Route
          path="/application-logs/central-log"
          element={<AsyncApplicationCentralLog />}
          exact
        />
        <Route
          path="/application-logs/system-error-log"
          element={<AsyncApplicationSystemErrorLog />}
          exact
        />

        <Route path="*" element={<Navigate to="/" />} />
      </Routes>
    </Suspense>
  );
}

export default PageRouter;
