export default (state, action) => {
    if (typeof state === "undefined") {
        return [];
    }
    switch (action.type) {
        case "USER_GET_SUCCESS":
        case "USER_GET_ERROR":
            return {
                ...state,
                GetUserListResponse: action.updatePayload,
            };
        case "CHANGE_PASSWORD_SUCCESS":
        case "CHANGE_PASSWORD_ERROR":
            return {
                ...state,
                ChangePasswordResponse: action.updatePayload,
            };
        case "SWITCH_USER_SUCCESS":
        case "SWITCH_USER_ERROR":
            return {
                ...state,
                SwitchUserResponse: action.updatePayload,
            };
        case "ADD_USER_SUCCESS":
        case "ADD_USER_ERROR":
            return {
                ...state,
                AddUserResponse: action.updatePayload,
            };
        case "UPDATE_USER_SUCCESS":
        case "UPDATE_USER_ERROR":
            return {
                ...state,
                UpdateUserResponse: action.updatePayload,
            };
        // case "DELETE_USER_SUCCESS":
        // case "DELETE_USER_ERROR":
        //     return {
        //         ...state,
        //         DeleteUserResponse: action.updatePayload,
        //     };
        case "FAKE_ACTION_USER":
            return [];
        default:
            return state;
    }
};
