import React, { useState, useEffect, useContext } from "react";
import Wrapper from "./style";
import { Navigate, Link } from "react-router-dom";
import { message } from "antd";
import { useSelector } from "react-redux";
import { GlobalContext } from "../../../commonContext";

export default function ({ loginAction, fakeActionAuth }) {
    const contextVar = useContext(GlobalContext);
    const LoginRes = useSelector((state) => state.Auth.LoginResponse || {});

    const [redirect, setRedirect] = useState(false);
    const [loading, setLoading] = useState(false);
    const [showPassword, setShowPassword] = useState(false);
    const [userData, setUserData] = useState({
        email: "admin@execuseller.com",
        password: "admin@execuseller.com",
    });

    useEffect(() => {
        if (LoginRes?.status === true) {
            setLoading(false);
            localStorage.setItem("user", JSON.stringify(LoginRes?.data?.user_data));
            contextVar?.updateCommonGlobalVal("profile", LoginRes?.data?.user_data);
            localStorage.setItem("token", LoginRes?.data?.auth_token_data?.access_token);
            localStorage.setItem("userType", LoginRes?.data?.user_data?.user_type);
            message.destroy();
            message.success(LoginRes?.message);
            setTimeout(() => {
                setRedirect(true);
            }, 300);
            fakeActionAuth();
        } else if (LoginRes?.status === false) {
            setLoading(false);
            message.destroy();
            message.warning(LoginRes?.message);
            fakeActionAuth();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [LoginRes]);

    const submit = () => {
        if (userData.email && userData.password) {
            setLoading(true);
            let body = {
                username: userData.email,
                password: userData.password,
            };
            loginAction(body);
        } else {
            message.destroy();
            message.warning("please fill inputs");
        }
    };

    if (redirect || localStorage.getItem("token")) {
        return <Navigate to="/manage-user" />;
    }

    return (
        <Wrapper>
            <div className="d-flex flex-column flex-root login-page" id="kt_app_root" style={{ height: '100%' }}>
                <div className="d-flex flex-column flex-column-fluid flex-lg-row justify-content-center">
                    <div className="d-flex flex-column-fluid flex-lg-row-auto justify-content-center justify-content-lg-end p-12 p-lg-40 sign-in">
                        <div className="bg-body d-flex flex-column align-items-stretch flex-center rounded-4 w-md-550px p-20 login-card">
                            <div className="d-flex flex-center flex-column flex-column-fluid px-lg-0 pb-15 pb-lg-0">
                                <Link to='/' className="mb-10">
                                    <img alt="Logo" src="assets/media/logo-white.png" className="h-70px" />
                                </Link>
                                <div className="form w-100">
                                    <div className="text-center mb-18">
                                        <h1 className="text-gray-100 fw-bold fs-2x mb-3">Welcome to <span className="text-primary">Execuseller</span> Platform</h1>
                                        <div className="text-gray-400 fw-semibold fs-6">Please enter your account details.</div>
                                    </div>
                                    <div className="fv-row mb-8">
                                        <input
                                            type="text"
                                            placeholder="Email"
                                            name="email"
                                            autoComplete="off"
                                            className="form-control bg-transparent h-60px text-light"
                                            value={userData?.email}
                                            onChange={(e) => {
                                                setUserData({
                                                    ...userData,
                                                    email: e.target.value,
                                                });
                                            }}
                                        />
                                    </div>
                                    <div className="fv-row mb-3 position-relative">
                                        <input
                                            type={showPassword ? 'text' : 'password'}
                                            id="password"
                                            placeholder="Password"
                                            name="password"
                                            autoComplete="off"
                                            className="form-control bg-transparent h-60px text-light"
                                            value={userData?.password}
                                            onChange={(e) => {
                                                setUserData({
                                                    ...userData,
                                                    password: e.target.value,
                                                });
                                            }}
                                        />
                                        <span onClick={() => setShowPassword(!showPassword)} className="btn btn-sm btn-icon position-absolute translate-middle top-50 end-0 me-n2 btn-pswrd">
                                            <i className={`ki-outline ki-eye-slash fs-2  ${showPassword ? 'd-none' : ''}`} />
                                            <i className={`ki-outline ki-eye fs-2 ${showPassword ? '' : 'd-none'}`} />
                                        </span>
                                    </div>
                                    <div className="d-flex flex-center mt-5 flex-wrap gap-3 fs-base fw-semibold mb-8">
                                        <span className="text-primary cursor-pointer">Forgot Password ?</span>
                                    </div>
                                    <div className="d-grid mb-10">
                                        <button
                                            onClick={() => submit()}
                                            className="btn btn-primary h-50px"
                                        >
                                            {loading ? (
                                                <span className="">
                                                    Please wait...
                                                    <span className="spinner-border spinner-border-sm align-middle ms-2" />
                                                </span>
                                            ) : (
                                                <span className="indicator-label">Sign In</span>
                                            )}
                                        </button>
                                    </div>
                                </div>
                            </div>
                            <div className="d-flex flex-stack px-lg-10 justify-content-center ">
                                <div className="text-gray-500 text-center fw-semibold fs-6">Not a Member yet?
                                    <span className="text-primary cursor-pointer"> Sign up</span></div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Wrapper>
    )

}