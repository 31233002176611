import React, { Suspense } from "react";
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import asyncComponent from "./core/asyncComponent";

import Login_ from "./containers/login";
import Page_ from "./modules/Page";

const AsyncLogin_ = asyncComponent(Login_)
const AsyncPage_ = asyncComponent(Page_)

function AppRoute(props) {
    return (
        <Router history={props.history}>
            <Suspense
                fallback={
                    <div className="overlayLoading">
                        <div className="loader"></div>
                    </div>
                }
            >
                <Routes>
                    <Route exact path="/login" element={<AsyncLogin_ />} />
                    <Route path="*" element={<AsyncPage_ />} />
                </Routes>
            </Suspense>
        </Router>
    )
}

export default AppRoute