export default (state, action) => {
    if (typeof state === "undefined") {
        return [];
    }
    switch (action.type) {
        case "GET_MANAGER_LIST_SUCCESS":
        case "GET_MANAGER_LIST_ERROR":
            return {
                ...state,
                GetManagerListResponse: action.updatePayload,
            };
        case "GET_ALL_MANAGER_LIST_SUCCESS":
        case "GET_ALL_MANAGER_LIST_ERROR":
            return {
                ...state,
                GetAllManagerListResponse: action.updatePayload,
            };
        case "ADD_MANAGER_SUCCESS":
        case "ADD_MANAGER_ERROR":
            return {
                ...state,
                AddManagerResponse: action.updatePayload,
            };
        case "UPDATE_MANAGER_SUCCESS":
        case "UPDATE_MANAGER_ERROR":
            return {
                ...state,
                UpdateManagerResponse: action.updatePayload,
            };
        case "DELETE_MANAGER_SUCCESS":
        case "DELETE_MANAGER_ERROR":
            return {
                ...state,
                DeleteManagerResponse: action.updatePayload,
            };
        case "FAKE_ACTION_ADD_MANAGER":
            return [];
        default:
            return state;
    }
};
