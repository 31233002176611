import React, { useState, useEffect, useContext, useMemo } from "react";
import { adminMenus, userMenus } from "./lib/menu";
import { Link, useLocation, } from "react-router-dom";
import { GlobalContext } from "../../../commonContext";

export default function (props) {
    const { hide, screenSize, deviceType } = props;
    const location = useLocation();
    const contextVar = useContext(GlobalContext);
    const user_ = contextVar?.data?.profile;
    const [current, setCurrent] = useState("dashboard");
    const [subMenuOpen, setSubMenuOpen] = useState('');

    const mainMenuList = (
        localStorage.getItem("userType") == 1 ? adminMenus : userMenus
    )

    useEffect(() => {
        const splitLoca = location.pathname.split("/");
        if (splitLoca[1]) {
            if (splitLoca.length > 2 && !location.pathname.includes('view')) {
                setCurrent(splitLoca[2]);
                setSubMenuOpen(splitLoca[1])
            } else {
                setCurrent(splitLoca[1]);
                setSubMenuOpen('')
            }
        } else {
            setCurrent("dashboard");
            setSubMenuOpen('')
        }
    }, [location]);

    return (
        <>
            <div id="kt_app_sidebar" className={`app-sidebar flex-column ${screenSize && !hide ? "drawer drawer-start drawer-on" : ""} `} style={{ overflow: 'hidden' }} data-kt-drawer="true" data-kt-drawer-name="app-sidebar" data-kt-drawer-activate="{default: true, lg: false}" data-kt-drawer-overlay="true" data-kt-drawer-width="250px" data-kt-drawer-direction="start" data-kt-drawer-toggle="#kt_app_sidebar_mobile_toggle">
                <div className="app-sidebar-header d-flex flex-stack d-none d-lg-flex pt-8 pb-2" id="kt_app_sidebar_header">
                    <Link to='/' className="app-sidebar-logo">
                        <img alt="Logo" src="/assets/media/logo.png" className="h-50px d-none d-sm-inline app-sidebar-logo-default" />
                    </Link>
                </div>
                <div className="app-sidebar-navs flex-column-fluid py-6" id="kt_app_sidebar_navs">
                    <div id="kt_app_sidebar_navs_wrappers" className="app-sidebar-wrapper hover-scroll-y my-2" data-kt-scroll="true" data-kt-scroll-activate="true" data-kt-scroll-height="auto" data-kt-scroll-dependencies="#kt_app_sidebar_header" data-kt-scroll-wrappers="#kt_app_sidebar_navs" data-kt-scroll-offset="5px">
                        <div className="app-sidebar-menu-secondary menu menu-rounded menu-column ">
                            <div className="card pc-user-card mb-7">
                                <div className="card-body p-3">
                                    <div className="menu-content d-flex align-items-center px-0">
                                        <div className="symbol symbol-50px me-5">
                                            <img alt="Logo" src={user_?.photo || '/assets/media/avatars/300-2.jpg'} />
                                        </div>
                                        <div className="d-flex flex-column">
                                            <div className="fw-bold d-flex align-items-center fs-5">{user_?.first_name || ''} {user_?.last_name || ''}</div>
                                            <span className="fw-semibold text-muted text-hover-primary fs-7">{user_?.email || ''}</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div id="#kt_app_sidebar_menu" data-kt-menu="true" data-kt-menu-expand="false" className="app-sidebar-menu-primary menu menu-column menu-rounded menu-sub-indention menu-state-bullet-primary">
                            <div className="menu-item mb-2">
                                <div className="menu-heading text-uppercase fs-7 fw-bold">{user_?.user_type === 1 ? 'Admin' : 'USER'}</div>
                                <div className="app-sidebar-separator separator" />
                            </div>
                            {mainMenuList.map((menu) => {
                                if (menu?.subMenu.length > 0) {
                                    return (<div onClick={() => setSubMenuOpen((prev) => (prev === menu?.id ? '' : menu?.id))} className={`menu-item menu-accordion ${subMenuOpen === menu?.id ? 'hover show' : ''}`}>
                                        <span className="menu-link">
                                            <span className="menu-icon">
                                                {menu?.icon}
                                            </span>
                                            <span className="menu-title">{menu?.title}</span>
                                            <span className="menu-arrow" />
                                        </span>
                                        <div className={`menu-sub menu-sub-accordion ${subMenuOpen === menu?.id ? 'show' : ''}`}>
                                            {menu?.subMenu.map((sub_menu) => {
                                                return (<div className="menu-item">
                                                    <Link to={sub_menu?.link} className={`menu-link ${current === sub_menu?.id ? 'active' : ''}`}>
                                                        <span className="menu-bullet">
                                                            <span className="bullet bullet-dot" />
                                                        </span>
                                                        <span className="menu-title">{sub_menu?.title}</span>
                                                    </Link>
                                                </div>)
                                            })}
                                        </div>
                                    </div>)
                                } else {
                                    return (<div className="menu-item ">
                                        <Link to={menu?.link} className={`menu-link ${current === menu?.id ? 'active' : ''}`}>
                                            <span className="menu-icon">
                                                {menu?.icon}
                                            </span>
                                            <span className="menu-title">{menu?.title}</span>
                                        </Link>
                                    </div>)
                                }
                            })}
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}