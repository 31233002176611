import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import Login from "../../modules/page/login";
import { loginAction, fakeActionAuth } from '../../redux/modules/auth/auth.action';

const mapStateToProps = (state) => ({
    LoginResponse: state.Auth.LoginResponse
});

const mapDispatchToProps = (dispatch) => bindActionCreators({
    loginAction, fakeActionAuth
}, dispatch);

const Login_ = connect(
    mapStateToProps,
    mapDispatchToProps
)(Login);

export default Login_;
