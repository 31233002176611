import styled from "styled-components";

const Wrapper = styled.div`
height: 100%;
.login-page { 
    background-image: url('assets/media/1.jpg');
    //  background-size: 100% 100%;
    background-attachment: fixed;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
} 
[data-bs-theme=\"dark\"] .login-page { 
    background-image: url('assets/media/3303324.jpg'); 
}
.login-card{
    background-color: #262323 !important;
    backdrop-filter: blur(15px);
    border: 2px solid #ffffff26;
}
`;

export default Wrapper;