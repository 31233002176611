import Auth from "../../modules/auth/auth.reducer";
import User from "../../modules/admin/manage-user/user.reducer";
import GeoCurrencyManager from "../../modules/admin/geo-currency-manager/geo-currency-manager.reducer";
import InventoryLedgerReport from "../../modules/user-panel/reports/inventory-ledger-report/index.reducer";
import ReimbursementReport from "../../modules/user-panel/reports/reimbursement-report/index.reducer";
import MarketPlace from "../../modules/user-panel/setting/marketplace/index.reducer";
import CentralLogSystem from "../../modules/user-panel/application-logs/central-log-system/index.reducer";
import SystemErrorLogs from "../../modules/user-panel/application-logs/system-error-logs/index.reducer";
import ReimbursementDetails from "../../modules/user-panel/reimbursement-details/index.reducer";
import AddManager from "../../modules/user-panel/setting/add-manager/index.reducer";
import Dashboard from "../../modules/dashboard/index.reducer";
import MonthlyReport from "../../modules/user-panel/monthly-report/index.reducer";
const reducers = {
  Auth,
  User,
  GeoCurrencyManager,
  InventoryLedgerReport,
  ReimbursementReport,
  MarketPlace,
  CentralLogSystem,
  SystemErrorLogs,
  ReimbursementDetails,
  AddManager,
  Dashboard,
  MonthlyReport,
};
export default reducers;
