import React, { useEffect, useState, useContext } from "react";
import { Dropdown, message } from "antd";
import { useLocation, Link, useNavigate } from "react-router-dom";
import { GlobalContext } from "../../../commonContext";
import { nameObject } from "../../../config/breadcrumb";

export default function (props) {
    const { hideSidebar } = props;
    const contextVar = useContext(GlobalContext);
    const user_ = contextVar?.data?.profile;
    const location = useLocation();
    const navigate = useNavigate();
    const [toggle, setToggle] = useState(false)
    const [current, setCurrent] = useState({
        name: "",
    });
    const [mode, setMode] = useState(false)
    const [languages, setLanguages] = useState(false)
    const [themeColor, setThemeColor] = useState('light')
    const defaultThemeMode = "light";

    const setThemeValue = (color) => {
        localStorage.setItem("data-bs-theme", color)
        let themeMode;
        if (document.documentElement) {
            if (document.documentElement.hasAttribute("data-theme-mode")) {
                themeMode = document.documentElement.getAttribute("data-theme-mode");
            } else {
                if (color) {
                    themeMode = color;
                } else {
                    themeMode = defaultThemeMode;
                }
            }
            if (themeMode === "system") {
                themeMode = window.matchMedia("(prefers-color-scheme: dark)").matches ? "dark" : "light";
            }
            document.documentElement.setAttribute("data-bs-theme", themeMode);
        }
        setThemeColor(color)
    }

    useEffect(() => {
        if (nameObject[location.pathname]) {
            setCurrent(nameObject[location.pathname]);
        } else {
            if (
                Object.keys(nameObject).findIndex((d) =>
                    d.includes("/" + location.pathname.split("/")[1])
                ) !== -1 ||
                location.pathname.includes("/callback_sp")
            ) {
                let name_ = nameObject["/" + location.pathname.split("/")[1]];
                return setCurrent(
                    name_ == undefined
                        ? {
                            name: "Marketplace Credentials",
                        }
                        : name_
                );
            }
            setCurrent({ name: "" });
        }
    }, [location]);

    useEffect(() => {
        let defaultColor = localStorage.getItem("data-bs-theme")
        setThemeValue(defaultColor)
        setThemeColor(defaultColor)
    }, [])

    const dropdown_menu = (
        <div className="menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-800 menu-state-bg menu-state-color fw-semibold py-4 fs-6 w-275px show">
            <div className="menu-item px-3">
                <div className="menu-content d-flex align-items-center px-0">
                    <div className="symbol symbol-50px me-5">
                        <img alt="Logo" src={user_?.photo || '/assets/media/avatars/300-2.jpg'} />
                    </div>
                    <div className="d-flex flex-column">
                        <div className="fw-bold d-flex align-items-center fs-5">{user_?.first_name || ''} {user_?.last_name || ''}</div>
                        <span className="fw-semibold text-muted text-hover-primary fs-7">admin@execuseller.com</span>
                    </div>
                </div>
            </div>
            <div className="separator my-2" />
            <div className="menu-item px-5">
                {localStorage.getItem('userType') === '2' ? <>
                    <Link to='/settings/profile' className="menu-link px-5">My Profile</Link>
                </> : <>
                    <span className="menu-link px-5">My Profile</span>
                </>}
            </div>
            <div className="separator my-2" />
            <div className="menu-item px-5" data-kt-menu-trigger="{default: 'click', lg: 'hover'}" data-kt-menu-placement="left-start" data-kt-menu-offset="-15px, 0">
                <span className="menu-link px-5" onClick={() => { setMode(!mode); setLanguages(false) }}>
                    <span className="menu-title position-relative">Mode
                        <span className="ms-5 position-absolute translate-middle-y top-50 end-0">
                            <i className="ki-outline ki-night-day theme-light-show fs-2" />
                            <i className="ki-outline ki-moon theme-dark-show fs-2" />
                        </span></span>
                </span>
                <div className={`menu-sub menu-sub-dropdown w-175px py-4 ${mode ? 'd-flex show' : ''}`} style={{ zIndex: 108, position: 'fixed', inset: 'auto auto 0px 30px', margin: '0px', transform: 'translate(-210px, -70px)' }}>
                    <div className="menu-item px-3 my-0">
                        <span className={`menu-link px-3 py-2 ${themeColor === 'light' ? 'active' : ''}`} onClick={() => setThemeValue("light")}>
                            <span className="menu-icon" data-kt-element="icon">
                                <i className="ki-outline ki-night-day fs-2" />
                            </span>
                            <span className="menu-title">Light</span>
                        </span>
                    </div>
                    <div className="menu-item px-3 my-0">
                        <span className={`menu-link px-3 py-2 ${themeColor === 'dark' ? 'active' : ''}`} onClick={() => setThemeValue("dark")}>
                            <span className="menu-icon" data-kt-element="icon">
                                <i className="ki-outline ki-moon fs-2" />
                            </span>
                            <span className="menu-title">Dark</span>
                        </span>
                    </div>
                    <div className="menu-item px-3 my-0">
                        <span className={`menu-link px-3 py-2 ${themeColor === 'system' ? 'active' : ''}`} onClick={() => setThemeValue("system")}>
                            <span className="menu-icon" data-kt-element="icon">
                                <i className="ki-outline ki-screen fs-2" />
                            </span>
                            <span className="menu-title">System</span>
                        </span>
                    </div>
                </div>
            </div>
            <div className="menu-item px-5" data-kt-menu-trigger="{default: 'click', lg: 'hover'}" data-kt-menu-placement="left-start" data-kt-menu-offset="-15px, 0">
                <span className="menu-link px-5" onClick={() => { setLanguages(!languages); setMode(false) }}>
                    <span className="menu-title position-relative">Language
                        <span className="fs-8 rounded bg-light px-3 py-2 position-absolute translate-middle-y top-50 end-0">English
                            <img className="w-15px h-15px rounded-1 ms-2" src="assets/media/flags/united-states.svg" alt="" /></span></span>
                </span>
                <div className={`menu-sub menu-sub-dropdown w-175px py-4 ${languages ? 'd-flex show' : ''}`} style={{ zIndex: 108, position: 'fixed', inset: 'auto auto 0px 30px', margin: '0px', transform: 'translate(-210px, 100px)' }}>
                    <div className="menu-item px-3">
                        <span className="menu-link d-flex px-5 active">
                            <span className="symbol symbol-20px me-4">
                                <img className="rounded-1" src="assets/media/flags/united-states.svg" alt="" />
                            </span>English</span>
                    </div>
                    <div className="menu-item px-3">
                        <span className="menu-link d-flex px-5">
                            <span className="symbol symbol-20px me-4">
                                <img className="rounded-1" src="/assets/media/flags/spain.svg" alt="" />
                            </span>Spanish</span>
                    </div>
                    <div className="menu-item px-3">
                        <span className="menu-link d-flex px-5">
                            <span className="symbol symbol-20px me-4">
                                <img className="rounded-1" src="/assets/media/flags/germany.svg" alt="" />
                            </span>German</span>
                    </div>
                    <div className="menu-item px-3">
                        <span className="menu-link d-flex px-5">
                            <span className="symbol symbol-20px me-4">
                                <img className="rounded-1" src="/assets/media/flags/japan.svg" alt="" />
                            </span>Japanese</span>
                    </div>
                    <div className="menu-item px-3">
                        <span className="menu-link d-flex px-5">
                            <span className="symbol symbol-20px me-4">
                                <img className="rounded-1" src="/assets/media/flags/france.svg" alt="" />
                            </span>French</span>
                    </div>
                </div>
            </div>
            <div className="menu-item px-5 my-1">
                <span className="menu-link px-5">Account Settings</span>
            </div>
            <div className="menu-item px-5">
                <Link
                    onClick={() => {
                        localStorage.clear();
                        contextVar?.updateCommonGlobalVal("profile", {});
                        window.location.assign("/login");
                    }}
                    className="menu-link bg-primary text-light rounded-5 px-5 justify-content-center" style={{ borderRadius: '40px' }}>Sign Out</Link>
            </div>
        </div>
    )

    const handleSidebarToggle = () => {
        const is_on = document.body.getAttribute('data-kt-app-sidebar-minimize')
        if (is_on === 'off') {
            document.body.setAttribute('data-kt-app-sidebar-minimize', 'on')
        } else {
            document.body.setAttribute('data-kt-app-sidebar-minimize', 'off')
        }
    }

    const backToAdmin = () => {
        let adminDataLocal = localStorage.getItem("adminData");
        if (adminDataLocal) {
            message.destroy();
            message.loading('Loading...', 0)
            adminDataLocal = JSON.parse(adminDataLocal);
            localStorage.setItem("user", adminDataLocal.data);
            contextVar?.updateCommonGlobalVal('profile', JSON.parse(adminDataLocal.data));
            localStorage.setItem(
                "userType",
                JSON.parse(adminDataLocal?.data)?.user_type
            );
            localStorage.setItem("token", adminDataLocal.token);
            localStorage.removeItem("adminData");
            setTimeout(() => {
                navigate('/manage-user')
            }, 300);
        }
    };

    return (
        <div id="kt_app_header" className="app-header">
            <div className="app-container container-fluid d-flex align-items-stretch flex-stack" id="kt_app_header_container">
                <div className="d-flex align-items-center d-block d-lg-none ms-n3" title="Show sidebar menu">
                    <div onClick={() => hideSidebar()} className="btn btn-icon btn-active-color-primary w-35px h-35px me-2" id="kt_app_sidebar_mobile_toggle">
                        <i className="ki-outline ki-abstract-14 fs-2" />
                    </div>
                    <Link to='/'>
                        <img alt="Logo" src="/assets/media/icon.png" className="h-35px rounded-1" />
                    </Link>
                </div>
                <div className="app-navbar flex-lg-grow-1" id="kt_app_header_navbar">
                    <div className="app-navbar-item me-1 me-md-3 ">
                        <div id="kt_app_sidebar_toggle" onClick={() => { handleSidebarToggle(); setToggle(!toggle) }} className={`app-sidebar-toggle btn btn-sm btn-icon bg-light btn-color-gray-700 d-lg-flex rotate w-35px h-35px w-md-40px h-md-40px ${toggle ? 'active' : ''}`} data-kt-toggle="true" data-kt-toggle-state="active" data-kt-toggle-target="body" data-kt-toggle-name="app-sidebar-minimize">
                            <i className="ki-outline ki-text-align-right rotate-180 fs-1" />
                        </div>
                    </div>
                    <div className="app-navbar-item d-flex align-items-stretch flex-lg-grow-1 ">
                        <div className="page-title d-flex flex-column justify-content-center gap-1 ms-3">
                            <h1 className="d-flex flex-column justify-content-center text-dark fw-bold fs-2 m-0">
                                {current?.name || ""}
                            </h1>
                            <ul className="breadcrumb breadcrumb-separatorless fw-semibold fs-7 my-0">
                                <li className="breadcrumb-item text-muted">
                                    <Link to='/' className="text-muted text-hover-primary">
                                        Home
                                    </Link>
                                </li>
                                <li className="breadcrumb-item">
                                    <span className="bullet bg-gray-400 w-5px h-2px" />
                                </li>
                                <li className="breadcrumb-item text-muted">
                                    {current?.parent ? current?.parent : 'Pages'}
                                </li>
                                <li className="breadcrumb-item">
                                    <span className="bullet bg-gray-400 w-5px h-2px" />
                                </li>
                                {current?.sub_parent ? <>
                                    <li className="breadcrumb-item text-gray-600 fw-bold lh-1">
                                        <Link to={current?.sub_link} className='cursor-pointer'>
                                            {current?.sub_parent}
                                        </Link>
                                    </li>
                                    <li className="breadcrumb-item">
                                        <i className="ki-outline ki-right fs-7 text-gray-700 mx-n1" />
                                    </li>
                                </> : <li className="breadcrumb-item text-muted">
                                    {current?.name ? current?.name : ''}
                                </li>}
                            </ul>
                        </div>
                    </div>
                    {localStorage.getItem("adminData") && <div className="app-navbar-item me-3 ms-md-6">
                        <div
                            onClick={() => backToAdmin()}
                            className="btn  fs-7 btn-primary fw-bold"
                            id="kt_activities_toggle"
                        >
                            back to admin
                        </div>
                    </div>}
                    <div className="app-navbar-item ms-1 ms-md-3">
                        <div id="kt_header_search" className="header-search d-flex align-items-center w-lg-200px" data-kt-search-keypress="true" data-kt-search-min-length={2} data-kt-search-enter="enter" data-kt-search-layout="menu" data-kt-search-responsive="true" data-kt-menu-trigger="auto" data-kt-menu-permanent="true" data-kt-menu-placement="bottom-start">
                            <div data-kt-search-element="toggle" className="search-toggle-mobile d-flex d-lg-none align-items-center">
                                <div className="d-flex">
                                    <i className="ki-outline ki-magnifier fs-1" />
                                </div>
                            </div>

                            <div className="d-none d-lg-block w-100 position-relative mb-5 mb-lg-0">
                                <input type="hidden" />
                                <i className="ki-outline ki-magnifier search-icon fs-2 text-gray-500 position-absolute top-50 translate-middle-y ms-5" />
                                <input
                                    type="text"
                                    className="search-input form-control form-control rounded-1 ps-13 fs-7"
                                    name="search"
                                    placeholder="Search..."
                                />
                                <span className="search-spinner position-absolute top-50 end-0 translate-middle-y lh-0 d-none me-5" data-kt-search-element="spinner">
                                    <span className="spinner-border h-15px w-15px align-middle text-gray-400" />
                                </span>
                                <span className="search-reset btn btn-flush btn-active-color-primary position-absolute top-50 end-0 translate-middle-y lh-0 d-none me-4" data-kt-search-element="clear">
                                    <i className="ki-outline ki-cross fs-2 fs-lg-1 me-0" />
                                </span>
                            </div>
                            <div data-kt-search-element="content" className="menu menu-sub menu-sub-dropdown py-7 px-7 overflow-hidden w-300px w-md-350px">
                                <div data-kt-search-element="wrapper">
                                    <div className data-kt-search-element="main">
                                        <div className="d-flex flex-stack fw-semibold mb-4">
                                            <span className="text-muted fs-6 me-2">Recently Searched:</span>
                                        </div>
                                        <div className="scroll-y mh-200px mh-lg-325px">
                                        </div>
                                    </div>
                                    <div data-kt-search-element="empty" className="text-center">
                                        <div className="pt-10 pb-10">
                                            <i className="ki-outline ki-search-list fs-4x opacity-50" />
                                        </div>
                                        <div className="pb-15 fw-semibold">
                                            <h3 className="text-gray-600 fs-5 mb-2">No result found</h3>
                                            <div className="text-muted fs-7">Please try again with a different query</div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="app-navbar-item ms-1 ms-md-3">
                        <div className="btn btn-icon btn-custom btn-color-gray-600 btn-active-light btn-active-color-primary w-35px h-35px w-md-40px h-md-40px" data-kt-menu-trigger="{default: 'click', lg: 'hover'}" data-kt-menu-attach="parent" data-kt-menu-placement="bottom-end">
                            <i className="ki-outline ki-calendar fs-1" />
                        </div>
                    </div>
                    <div className="app-navbar-item ms-1 ms-md-3">
                        <div className="btn btn-icon btn-custom btn-color-gray-600 btn-active-light btn-active-color-primary w-35px h-35px w-md-40px h-md-40px" data-kt-menu-trigger="{default: 'click', lg: 'hover'}" data-kt-menu-attach="parent" data-kt-menu-placement="bottom-end">
                            <i className="ki-outline ki-abstract-26 fs-1" />
                        </div>
                    </div>
                    <div className="app-navbar-item ms-1 ms-md-3">
                        <div className="btn btn-icon btn-custom btn-color-gray-600 btn-active-light btn-active-color-primary w-35px h-35px w-md-40px h-md-40px position-relative" id="kt_drawer_chat_toggle">
                            <i className="ki-outline ki-notification-on fs-1" />
                            <span className="position-absolute top-0 start-100 translate-middle badge badge-circle badge-danger w-15px h-15px ms-n4 mt-3">5</span>
                        </div>
                    </div>
                    <div className="app-navbar-item ms-1 ms-md-3" id="kt_header_user_menu_toggle">
                        <Dropdown overlay={dropdown_menu} trigger={['click']} onClick={() => { setMode(false); setLanguages(false); }}>
                            <div className="cursor-pointer symbol symbol-circle symbol-md-45px">
                                <img src={user_?.photo || '/assets/media/avatars/300-2.jpg'} alt="user" />
                            </div>
                        </Dropdown>
                    </div>
                </div>
                <div className="app-navbar-separator separator d-none d-lg-flex" />
            </div>
        </div>
    )

}